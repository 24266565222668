<template>
  <div class="d-inline-block">
    <el-drawer
      title=""
      :visible.sync="showVisibleDrawer"
      :with-header="false"
      custom-class="wrapper__terms-condition-drawer"
      @closed="onCloseDrawer"
      size="40%"
    >
      <section class="p-4">
        <div class="d-flex justify-content-center mb-4">
          <LogoComponent />
        </div>
        <p class="text-center">
          TÉRMINOS Y CONDICIONES DE USO Y ACCESO AL SITIO WEB
        </p>

        <p class="text-center">
          <a
            href="http://app.mydessk.com"
            target="_blank"
            rel="noopener noreferrer"
            >https://app.mydessk.com</a
          >
        </p>

        <p class="font-bold">1. Introducción</p>
        <p class="">
          <strong>MYDESSK CÍA. LTDA.,</strong> es la persona jurídica regulada
          por las leyes ecuatorianas, propietaria de la app web
          <a
            href="http://app.mydessk.com"
            target="_blank"
            rel="noopener noreferrer"
            >https://app.mydessk.com</a
          >
          (en adelante “MYDESSK” o “el titular”), que es una herramienta de
          bussiness inteligence, para la gestión de ventas de negocios y
          emprendimientos. Su teléfono fijo de contacto es (+593) 042900306 y el
          correo electrónico donde podrá recibir notificaciones es
          <a href="mailto:info@mydessk.com.">info@mydessk.com.</a>

          <br /><br />

          <strong>MYDESSK</strong> no es responsable de la veracidad de
          información que suben sus usuarios a la plataforma, ni de su
          contenido, ni de su origen o destino, pues, la plataforma actúa como
          proveedor del servicio.

          <br /><br />

          <strong>MYDESSK</strong> no se responsabiliza con terceros de la
          licitud de las ventas o cotizaciones efectuadas por sus usuarios que
          hayan utilizado la plataforma, ni tampoco responderá a terceros sobre
          cualquier reclamo que se interponga en contra del usuario, en relación
          a la prestación de sus servicios profesionales.

          <br /><br />

          Estos términos y condiciones generales regulan la puesta a disposición
          de información, el acceso y utilización de los servicios
          proporcionados por la plataforma de app.mydessk.com (en adelante “la
          plataforma” o “el portal” o “la página”) administrados por el titular,
          y constituye el acuerdo legal que regulan todos y cada uno de los
          servicios e información que se facilitan desde el portal. El mero
          acceso y/o utilización del portal o la aplicación, de todos o parte de
          sus contenidos y/o servicios, o crear una cuenta o hacer clic en uno
          de sus íconos, significa la
          <strong>plena aceptación y consentimiento del acuerdo legal. </strong>
        </p>

        <p class="font-bold">
          2.- Declaraciones previas a la utilización del portal
        </p>
        <p class="">
          Para poder acceder y utilizar los servicios del portal, el usuario
          declara tener al menos dieciocho años de edad. Si usted es menor edad,
          no podrá utilizar los servicios MYDESSK.

          <br /><br />

          Al crear una cuenta y/o adquirir un producto o servicio, usted
          garantiza que es mayor de edad y que, por lo tanto, tiene capacidad
          legal para poder utilizar la aplicación; y, en general, para suscribir
          contratos y actos jurídicos.

          <br /><br />

          El usuario queda expresamente apercibido que los Términos y
          Condiciones de Uso del sitio web pueden sufrir modificaciones y
          variaciones en el futuro, a libre criterio de su titular. Será
          responsabilidad del usuario verificar los Términos y Condiciones de
          Uso y sus respectivas modificaciones, cada vez que utilice el sitio
          web. Si por los cambios realizados el usuario no desee continuar
          utilizando la página, se le conmina a que cierre su cuenta de forma
          inmediata.
        </p>

        <p class="font-bold">3.- Glosario de definiciones</p>
        <p class="">
          A efectos de los presentes Términos y Condiciones de Uso, los
          siguientes términos tendrán el sentido que se indica a continuación:

          <br /><br />

          <strong>“Usuario”:</strong> Es la persona natural o jurídica, que se
          registra y utiliza de forma gratuita o pagada los servicios que ofrece
          la aplicación MYDESSK. Para efectos de aplicación de los presentes
          Términos y Condiciones de Uso, el simple hecho de visualizar, utilizar
          o navegar en la página será hecho suficiente para otorgarle dicha
          calidad y estar sometido a su cumplimiento.

          <br /><br />

          <strong>“Barra de herramientas”:</strong> Es un conjunto de opciones
          de administración, presentadas en el menú desplegado en la pantalla
          principal, que permiten obtener información acerca del estado de la
          cuenta del usuario. Se encuentran opciones como: ventas, contactos,
          catálogo, contratos, reportes, etc. Es el panel ubicado en la parte
          superior de la página, en donde se pueden visualizar los principales
          comandos que despliega la aplicación.

          <br /><br />

          <strong>“Dashboard”:</strong> Es el tablero o cuadro de mandos en
          donde se refleja la representación gráfica y análisis de data de las
          facturas, cotizaciones y ventas, que se realizan de forma automática
          en el marco de la información que el usuario comparta con la
          aplicación.

          <br /><br />

          <strong>“Cotizaciones”:</strong> Es la función del portal que permite
          al usuario poder realizar invitaciones a negociar para sus clientes,
          con todos los detalles, como el producto o servicios, precio,
          descuento y su plazo de vigencia. Las cotizaciones no son comprobantes
          de venta.

          <br /><br />

          <strong>“Ventas”:</strong> Es el comando ubicado en la barra de
          herramientas, que agrupa las secciones de cotizaciones, facturas,
          notas de crédito y notas de débito.

          <br /><br />

          <strong>“Contactos”:</strong> Es el comando ubicado en la barra de
          herramientas que sirve para que el usuario registre la información
          sobre sus clientes y contactos, a quienes se les envían las
          cotizaciones de sus productos y servicios.

          <br /><br />

          <strong>“Catálogo”:</strong> Es el comando ubicado en la barra de
          herramientas que permite al usuario registrar los productos y
          servicios, de manera automatizada, que forman parte de su actividad
          empresarial o portafolio, los que podrán ser utilizados para envío de
          cotizaciones y facturas electrónicas.

          <br /><br />

          <strong>“Contratos”:</strong> Es el comando ubicado en la barra de
          herramientas que permite al usuario crear modelos personalizados de
          contratos, de cualquier clase, que pueden ser utilizados para la
          formalización de los negocios y de las cotizaciones.

          <br /><br />

          <strong>“Reportes”:</strong> Es la herramienta que realiza el análisis
          de datos, con relación a las facturas, cotizaciones y las ventas, con
          el propósito de generar conclusiones útiles para el usuario.

          <br /><br />

          <strong>“Tipos de cuentas o usuarios”:</strong> Es la clasificación
          del tipo de usuario que ingresa a plataforma MYDESSK, que dependerá
          del valor de su suscripción.

          <br /><br />

          <strong>“Facturación electrónica”:</strong> Es la herramienta dentro
          de la aplicación que permite emitir facturas de venta en formato
          original, con todos los requisitos de la normativa tributaria del
          Servicio de Rentas Internas, garantizando su autenticidad, integridad
          y confidencialidad de la información. El comando de facturación
          electrónica tiene un costo adicional para el usuario.

          <br /><br />

          <strong>“Botón de pagos”:</strong> El servicios proporcionado por
          MYDESSK mediante el cual se pueden realizar operaciones y
          transacciones especiales a través de canales de comercio electrónico e
          internet, en las cuales se acepte como medio de pago las tarjetas de
          crédito, a través del servicio de “DATAWEB” o “Botón de Pagos”..
        </p>

        <p class="font-bold">4.- Condiciones del servicio para el usuario.</p>
        <p class="">
          4.1.- El usuario se obliga al cumplimiento de los Términos y
          Condiciones de Uso, sea por su registro, de forma gratuita o pagada, o
          su mero acceso.

          <br /><br />
          4.2.- El usuario se obliga a obrar siempre conforme a la ley, a las
          buenas costumbres, y a las exigencias de buena fe, empleando la
          diligencia debida, al momento de utilizar la aplicación. Queda
          prohibida la utilización y visualización de la página web por menores
          de edad.
          <br /><br />
          4.3.- Para empezar a hacer uso del sitio, el usuario deberá
          registrarse y crear un perfil, con una cuenta de correo electrónico y
          contraseña válidas. Deberá seleccionar el tipo de cuenta y realizar el
          pago para el uso, en caso que aplique. De encontrarse irregularidades
          en la cuenta de perfil, la página se reserva el derecho a notificar
          esta circunstancia o eliminar la cuenta.

          <br /><br />

          4.4.- El usuario declara y asegura la veracidad de la información
          compartida, del catálogo, contactos y las ventas, provienen de una
          oferta de buena fe de bienes o servicios.

          <br /><br />
          4.5.- El usuario declara que los datos, contenidos, archivos,
          elementos y demás información general que desee compartir y subir en
          la plataforma, no vulnera derechos de propiedad intelectual de
          terceros.
          <br /><br />
          4.6.- El usuario podrá utilizar, dependiendo de su tipo de cuenta,
          todas las funciones incluidas en la barra de herramientas, incluyendo
          el dashboard, cotizaciones, ventas, contactos, catálogos, contratos y
          reportes, así como el servicio de botón de pagos para gestionar la
          compra y el servicio de facturación electrónica. El usuario declara
          que todos los actos, negocios, transacciones, pagos, facturación y
          demás, que se realicen a través de su cuenta, serán de su exclusivo
          control y responsabilidad. MYDESSK no se responsabiliza por ningún
          acto realizado por el USUARIO mediante la utilización de su cuenta.
          <br /><br />
          4.7.- Si bien es cierto MYDESSK no se responsabiliza por la prestación
          de los servicios que realiza el usuario con sus clientes, se exhorta a
          que el usuario registre cotizaciones que sean fidedignas y reales, en
          cuanto a las condiciones de su negocio; que se someta al cumplimiento
          de los precios y cantidades ofertadas, y que envíe a través de la
          plataforma las facturas electrónicas en cuanto a la prestación de
          bienes y servicios lícitos, en el marco de las leyes ecuatorianas.
          <br /><br />

          4.8.- El usuario está obligado a respetar los derechos de propiedad
          intelectual e industrial del portal MYDESSK y adicionalmente, de
          terceros, principalmente las obras literarias, símbolos, nombres,
          imágenes, marcas, diseños industriales e indicaciones geográficas, que
          se publiquen a través de la página web, absteniéndose de copiar,
          reproducir, comunicar, o distribuir en forma alguna, a menos que
          cuente con las respectivas autorizaciones. En caso de controversias
          legales sobre violación a los derechos de propiedad intelectual y
          propiedad industrial, la plataforma no se responsabilizará con
          terceros para indemnizar los perjuicios que pudiesen ser ocasionados
          por el usuario.

          <br /><br />

          4.9.- El usuario no podrá utilizar la plataforma para transmitir,
          almacenar, divulgar promover o distribuir datos o contenidos que sean
          portadores de virus o cualquier otro código informático, archivos o
          programas diseñados para interrumpir, destruir o perjudicar el
          funcionamiento de cualquier programa o equipo informático o de
          telecomunicaciones. En caso que el portal detecte que algún dato o
          contenido dañino haya sido subido por algún usuario en la página,
          inmediatamente procederá a su bloqueo y eliminará su cuenta del
          registro, sin perjuicio que ponga a conocimiento de las autoridades
          respectivas la anomalía suscitada, para que se inicien las
          investigaciones pertinentes.
          <br /><br />
          4.10.- El usuario expresamente acepta y autoriza a MYDESSK para
          retener los valores, comisiones, cargos de tarjeta de crédito, débito,
          comisiones, impuestos y demás rubros que se detallan en la Cláusula
          “Política de Retención de valores” de MYDESSK.
        </p>

        <p class="font-bold">
          5.- Responsabilidad del portal web
        </p>
        <p class="">
          El usuario y cualquier persona que utilice la página, aceptan que
          MYDESSK no otorga ninguna garantía de cualquier naturaleza, ya sea
          expresa o implícita, sobre los datos, contenidos, información, calidad
          de bienes y prestación de servicios que se ofrecen a través del
          portal. La responsabilidad del portal web se limita a ofrecer una
          herramienta para administrar negocios y automatizar procesos de
          profesionales en una sola plataforma. No asume responsabilidad por la
          veracidad de las cotizaciones, productos, servicios, facturación,
          contratos y demás información que es suministrada exclusivamente por
          los usuarios.

          <br /><br />
          MYDESSK no garantiza ni asume responsabilidad alguna respecto a los
          posibles daños y perjuicios causados por el uso y utilización de la
          información, datos y servicios de la página web. En todo caso, el
          portal no será responsable por los daños y perjuicios que puedan
          deberse a la información y/o servicios prestados o suministrados por
          los usuarios del portal.
          <br /><br />
          Para los servicios pagados, el usuario declara que conoce que MYDESSK,
          a su vez, ha contratado con un tercero los servicios de botón de pagos
          y facturación electrónica; razón por la cual, el uso y calidad de este
          servicio está sujeto a las condiciones de prestación que implemente
          esta última, sin que se pueda atribuir a MYDESSK responsabilidad
          alguna, en caso que el servicio de facturación electrónica se
          encuentre caído o saturado, o en general, defectuoso. En este
          contexto, MYDESSK se compromete a realizar las gestiones para que el
          servicio sea prestado de forma ininterrumpida, bajo las condiciones
          contractuales acordadas, para su uso por parte de los usuarios de la
          plataforma.
        </p>

        <p class="font-bold">
          6.- Política de Retención de valores por botón de pagos:
        </p>
        <p class="">
          El usuario declara y acepta que la venta de cualquier producto y/o
          servicio, que se cobre a través del botón de pagos de MYDESSK estará
          gravado con Impuesto a la Renta e Impuesto al Valor Agregado según los
          términos de la Ley Orgánica de Régimen Tributario Interno; se gravarán
          los cargos del uso de botón de pagos, los cargos de servicio por
          utilización tarjeta de crédito o débito, y, además, que MYDESSK tendrá
          derecho a retener un porcentaje por comisión, por la venta realizada.
          De igual forma, el valor por la compra realizada a través de la
          plataforma. será transferido por MYDESSK hacia la cuenta del usuario,
          luego de haber realizado las correspondientes retenciones, según el
          tipo de tarjeta o Banco.

          <br /><br />

          Los valores que se podrán retener por MYDESSK, se detallan a
          continuación:

          <br /><br />
          <!-- table -->
          <br /><br />

          El usuario declara expresamente que no tendrá nada que reclamar a
          MYDESSK por los valores que haya recibido de parte de MYDESKK, una vez
          hechas las retenciones antes indicadas, por lo que desde ya se
          encuentra de conformidad con los mismos.
        </p>

        <p class="font-bold">7.- Propiedad intelectual e industrial:</p>
        <p class="">
          Todos los derechos de propiedad intelectual e industrial de la página
          web
          <a
            href="https://app.mydessk.com"
            target="_blank"
            rel="noopener noreferrer"
            >https://app.mydessk.com</a
          >, que incluyen los elementos contenidos en la misma (a título
          enunciativo: imágenes, sonido, audio, vídeo, software o textos, marcas
          o logotipos, combinaciones de colores, estructura y diseño, selección
          de materiales usados, programas de ordenador necesarios para su
          funcionamiento, acceso y uso, etc.) corresponden a la(s) persona(s)
          naturales accionistas y/o a la compañía MYDESSK CIA. LTDA., empresa
          propietaria de la página web, o de terceros titulares que han
          autorizado su inclusión en el portal. <br /><br />
          En virtud de lo dispuesto en el artículo 120 de la Código Orgánico de
          la Economía Social de los Conocimientos, Creatividad e Innovación
          queda expresamente prohibidas la reproducción, la distribución y la
          comunicación pública, incluida su modalidad de puesta a disposición,
          de la totalidad o parte de los contenidos de esta página web, con
          fines comerciales, en cualquier soporte y por cualquier medio técnico,
          o cualquier tipo de explotación en general, sin la autorización del o
          los titulares de los derechos patrimoniales. En ningún caso se
          entenderá que el titular de la página extiende licencia alguna o
          efectúa renuncia, transmisión, cesión total o parcial de dichos
          derechos.
          <br /><br />
          El usuario se compromete a respetar los derechos de propiedad
          intelectual e industrial de titularidad de las personas naturales
          propietarias o accionistas de la página web. Podrá visualizar los
          elementos del portal e incluso imprimirlos, copiarlos y almacenarlos
          en el disco duro de su ordenador o en cualquier otro soporte físico
          siempre y cuando sea, única y exclusivamente, para su uso personal y
          privado. Se encuentra totalmente prohibida la introducción con fines
          comerciales de hiperenlaces en otras páginas que lleven hacia el sitio
          web y menciones o creaciones de perfiles en redes sociales, sin la
          autorización expresa del titular.
        </p>

        <p class="font-bold">8.- Política de privacidad:</p>
        <p class="">
          La política de privacidad del portal
          <a
            href="https://app.mydessk.com"
            target="_blank"
            rel="noopener noreferrer"
            >https://app.mydessk.com</a
          >
          está destinada para informar a los usuarios, sobre sus prácticas de
          recopilación, uso y divulgación de la información personal que
          proporcionan a través de la página.

          <br /><br />

          Al acceder o utilizar los servicios ofertados por el portal
          <a
            href="https://app.mydessk.com"
            target="_blank"
            rel="noopener noreferrer"
            >https://app.mydessk.com</a
          >, el usuario da su consentimiento para recopilar, usar o divulgar su
          información personal recogida, de acuerdo a la presente Política de
          Privacidad.

          <br /><br />

          Por medio de este portal web se recogen datos de carácter personal
          necesarios para la utilización, gestión y mantenimiento de nuestros
          servicios. Dicha información será incluida en nuestras bases de datos,
          que se encuentran resguardadas con mecanismos de protección
          informática y digital, cumpliendo con la normativa de protección de
          datos a nivel internacional, y en especial la Ley Orgánica de
          Protección de Datos Personales, la Ley de Comercio Electrónico, Firmas
          Electrónicas, y Mensajes de Datos y la Constitución del Ecuador.
          <br /><br />
          Se deja constancia que el portal no recoge datos considerados como
          “sensibles”, esto es etnia, identidad de género, religión, ideología
          política, filiación política, pasado judicial, condición migratoria,
          orientación sexual, estado de salud, etc., por cuanto la naturaleza de
          la prestación del servicio no requiere de la recopilación de dicha
          información catalogada como sensible.
        </p>

        <p class="font-bold">9.- Confidencialidad de la información:</p>
        <p class="">
          9.1.- Las partes declaran que en virtud del presente contrato
          intercambiarán información confidencial.
          <br /><br />
          9.2.- La información confidencial está definida por todo tipo de
          información y documento transmitido o entregado entre las partes de
          este acuerdo, de manera escrita, oral o de cualquier otra forma. La
          información puede incluir ideas, conceptos, planes de negociaciones,
          reportes de reuniones, invenciones, descubrimientos, fórmulas,
          procedimientos, diseños, especificaciones, gráficos, prototipos,
          muestras, mejoras, desarrollos de aplicaciones de Ingeniería de
          software o productos de tecnología manufacturados, etc. nombres de
          mercadeo de productos o elementos, ya sea o no que dichos productos o
          elementos estén patentados o registrados o públicamente protegidos.
          <br /><br />
          9.3.- Las partes se obligan a mantener bajo total confidencialidad
          cualquier tipo de información de naturaleza confidencial entregada por
          la parte originadora, tomando toda precaución y ejecutando
          procedimientos de control internos al respecto.
          <br /><br />
          9.4.- Las partes se obligan a utilizar la información confidencial
          únicamente con los propósitos descritos en antecedentes de este
          acuerdo y no usarla para otro fin.
          <br /><br />
          9.5.- Las obligaciones contenidas en este acuerdo se deben mantener
          por un período de cinco (5) años contados desde el momento en que
          cualquier tipo de información confidencial haya sido entregada a su
          contraparte independientemente de la fecha de la firma de este
          acuerdo.
          <br /><br />
        </p>

        <p class="font-bold">
          10.- Legislación aplicable, jurisdicción competente y notificaciones
        </p>
        <p class="">
          Los presentes términos y condiciones de uso, se rigen y se interpretan
          de acuerdo con las Leyes de la República del Ecuador.
          <br /><br />
          En todo caso, para cualquier reclamación sobre la utilización de los
          servicios que brinda el portal web app.mydessk.com, que llegase a
          originar una demanda judicial, el usuario expresamente renuncia a su
          domicilio personal y se somete a la competencia de los jueces del
          cantón Guayaquil de la provincia del Guayas.
          <br /><br />
          Todas las notificaciones, requerimientos, peticiones y, en general,
          cualquier comunicación, incluyendo cualquier citación judicial, que se
          realicen en el marco de alguna demanda interpuesta por el usuario por
          el uso de la plataforma, por la prestación de servicios de la misma,
          deberán enviarse a la siguiente dirección electrónica:
          <a href="mailto:info@mydessk.com">info@mydessk.com</a>.
        </p>
      </section>
    </el-drawer>
    <a
      href="#"
      @click="onOpenDrawer"
      class="text-color-secondary"
      rel="noopener noreferrer"
      >Termínos y Condiciones</a
    >
  </div>
</template>

<script>
import LogoComponent from "@/components/Logo/Logo";

export default {
  name: "TermsCondition",
  data() {
    return {
      showVisibleDrawer: false
    };
  },
  methods: {
    onOpenDrawer() {
      this.showVisibleDrawer = true;
    },
    onCloseDrawer() {
      this.showVisibleDrawer = false;
    }
  },
  props: {},
  components: {
    LogoComponent
  }
};
</script>

<style lang="scss" scoped>
.wrapper__terms-condition-drawer {
  section {
    white-space: break-spaces !important;
  }
}
</style>
