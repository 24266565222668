<template>
  <b-container fluid>
    <b-row style="height: 100vh">
      <b-col md="5" lg="4" class="p-0 d-none d-sm-block">
        <SideBarComponent />
      </b-col>
      <b-col md="7" lg="8" class="py-4">
        <NavComponent />
        <main class="wrapper d-flex align-items-center">
          <el-form>
            <el-steps :active="active" finish-status="success">
              <el-step title=""></el-step>
              <el-step title=""></el-step>
              <el-step title=""></el-step>
              <el-step title=""></el-step>
              <el-step title=""></el-step>
            </el-steps>

            <AlertInfoComponent
              title="Información registro"
              :description="errorsResponse"
              v-if="registerResponse"
              class="mb-4"
            />
            <TransitionBounceComponent>
              <section v-if="active === 0" class="px-3">
                <h2 class="h3 text-md-left font-semi-bold mb-2r">
                  Empieza ahora, es
                  <span class="font-weight-bolder">Gratis</span>
                </h2>
                <el-form
                  :model="registerForm"
                  :rules="rules"
                  ref="registerForm"
                >
                  <el-form-item prop="email">
                    <slot name="label">
                      <label class="font-semi-bold">Correo electrónico</label>
                    </slot>
                    <el-input
                      v-model="registerForm.email"
                      placeholder=""
                    ></el-input>
                  </el-form-item>
                </el-form>
              </section>
            </TransitionBounceComponent>

            <TransitionBounceComponent>
              <section v-if="active === 1" class="px-3">
                <h2 class="h3 text-md-left font-semi-bold mb-2r">
                  Ingresa tus datos
                </h2>
                <el-form
                  :model="registerForm"
                  :rules="rules"
                  ref="registerForm"
                >
                  <el-form-item prop="email">
                    <slot name="label">
                      <label class="font-semi-bold">Correo electrónico</label>
                    </slot>
                    <el-input
                      placeholder=""
                      v-model="registerForm.email"
                      readonly
                      disabled="disabled"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="name">
                    <slot name="label">
                      <label class="font-semi-bold">Nombre</label>
                    </slot>
                    <el-input
                      placeholder=""
                      v-model="registerForm.name"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="last_name">
                    <slot name="label">
                      <label class="font-semi-bold">Apellido</label>
                    </slot>
                    <el-input
                      placeholder=""
                      v-model="registerForm.last_name"
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="password">
                    <slot name="label">
                      <label class="font-semi-bold">Contraseña</label>
                    </slot>
                    <el-input
                      v-model="registerForm.password"
                      placeholder=""
                      type="password"
                      show-password
                    ></el-input>
                  </el-form-item>
                </el-form>
              </section>
            </TransitionBounceComponent>

            <TransitionBounceComponent>
              <section v-if="active === 2" class="px-3">
                <h2 class="h3 text-md-left font-semi-bold mb-2r">
                  Ingresa la información de tu empresa
                </h2>
                <el-form
                  :model="registerForm"
                  :rules="rules"
                  ref="registerForm"
                >
                  <el-form-item prop="business_name">
                    <slot name="label">
                      <label class="font-semi-bold">Nombre de la empresa</label>
                    </slot>
                    <el-input
                      v-model="registerForm.business_name"
                      placeholder=""
                    ></el-input>
                  </el-form-item>

                  <el-form-item prop="people_number">
                    <slot name="label">
                      <label class="font-semi-bold">Cantidad de Personas</label>
                    </slot>

                    <el-radio-group
                      v-model="registerForm.people_number"
                      class="d-block"
                    >
                      <el-radio-button
                        v-for="peopleNumber in peopleNumbers"
                        :label="peopleNumber.value"
                        :key="peopleNumber.value"
                      >
                        {{ peopleNumber.label }}
                      </el-radio-button>
                    </el-radio-group>
                  </el-form-item>

                  <el-form-item prop="category_id">
                    <slot name="label">
                      <label class="font-semi-bold">Categoría</label>
                    </slot>
                    <el-select
                      placeholder="Seleccionar"
                      v-model="registerForm.category_id"
                    >
                      <el-option
                        v-for="category in categories"
                        :key="category.id"
                        :label="category.name"
                        :value="category.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item prop="has_crm_using">
                    <slot name="label">
                      <label class="font-semi-bold"
                        >¿Cúal es tu experiencia con las herramientas de
                        ventas?</label
                      >
                    </slot>
                    <el-select
                      placeholder="Seleccionar"
                      v-model="registerForm.has_crm_using"
                    >
                      <el-option
                        v-for="(item, index) in hasCrmUsing"
                        :key="index"
                        :label="item"
                        :value="item"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-form>
              </section>
            </TransitionBounceComponent>

            <TransitionBounceComponent>
              <section v-if="active === 3" class="px-3">
                <h2 class="h3 text-md-left font-semi-bold">
                  ¡Un paso más! Ingresa el logo de tu empresa
                </h2>
                <p class="mb-2r font-semi-bold">
                  Se podrá visualizar tu logo en la cotizaciones y documentos
                  emitidos a tus clientes.
                </p>

                <el-form
                  :model="registerForm"
                  :rules="rules"
                  ref="registerForm"
                >
                  <div class="image-selected" v-if="registerForm.business_logo">
                    <i
                      class="el-icon-circle-close"
                      @click="handleRemoveImage"
                    ></i>
                    <img :src="registerForm.business_logo" class="image-logo" />
                  </div>
                  <el-upload
                    class="upload-demo"
                    action="#"
                    drag
                    :auto-upload="false"
                    :show-file-list="true"
                    :multiple="false"
                    :limit="1"
                    ref="upload"
                    :on-change="handleChangeFile"
                    accept=".png, .jpg, .jpeg"
                    v-else
                  >
                    <div>
                      <i
                        class="el-icon-picture-outline-round el-icon-upload"
                      ></i>
                      <div class="el-upload__text">
                        <strong
                          >Arrastra una imagen <br />
                          o <br /> </strong
                        ><a href="#" class="a-link"
                          ><strong>Seleccionar del ordenador</strong></a
                        >
                      </div>
                      <div slot="tip" class="el-upload__tip">
                        <small
                          >Medida ideal 400x400px <br />
                          Formato jpg o png</small
                        >
                      </div>
                    </div>
                  </el-upload>

                  <el-form-item prop="conditions">
                    <el-radio
                      name="conditions"
                      label="true"
                      v-model="registerForm.conditions"
                    >
                      <label class="font-semi-bold"
                        >Acepto que he leído los <TermsCondition
                      /></label>
                    </el-radio>
                  </el-form-item>
                </el-form>
              </section>
            </TransitionBounceComponent>

            <TransitionBounceComponent>
              <section v-if="active === 4" class="px-3">
                <h2 class="h3 text-md-left font-semi-bold">
                  ¡Felicidades! Tu cuenta se ha creado
                </h2>
                <p class="mb-2r font-semi-bold">
                  Ahora puedes comenzar a Administrar tus propuestas y
                  presupuestos con nosotros.
                  <!-- <router-link
                    class="a-link font-semi-bold"
                    :to="{ name: 'login' }"
                  >
                    <strong>Inicia sesión ahora</strong></router-link
                  > -->
                </p>
              </section>
            </TransitionBounceComponent>

            <section
              class="btn-footer-steps d-flex justify-content-between align-items-center px-3"
              v-if="!successRegisterResponse"
            >
              <a
                href="javascript:;"
                @click.prevent="backStep"
                class="back-steps a-link"
              >
                <i class="el-icon-arrow-left" v-if="active > 0"></i>
              </a>
              <button
                type="button"
                class="btn md__btn-primary px-5 my-4"
                name=""
                @click="nextStep"
              >
                Siguiente <i class="el-icon-arrow-right"></i>
              </button>
            </section>
          </el-form>
        </main>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import SideBarComponent from "../../components/SideBar/SideBar";
import NavComponent from "@/components/Nav/Nav";
import TransitionBounceComponent from "@/components/Transitions/Bounce";
import AlertInfoComponent from "@/components/Alerts/Info";
import TermsCondition from "@/components/General/TermsCondition";

import CategoriesServices from "@/services/CategoriesServices";
import { PeopleNumbers } from "@/services/PeopleNumbers";
import Validate from "@/assets/validate";
import { fileBase64 } from "@/assets/utils/files";

export default {
  name: "RegisterPage",
  data() {
    return {
      loading: false,
      registerResponse: false,
      successRegisterResponse: false,
      errorsResponse: {},
      active: 0,
      registerForm: {
        email: "",
        password: "",
        name: "",
        last_name: "",
        business_name: "",
        people_number: "",
        category_id: "",
        business_logo: "",
        conditions: "",
        has_crm_using: ""
      },
      rules: {
        email: [{ ...Validate.rules.required(), ...Validate.rules.email() }],
        password: [Validate.rules.required()],
        name: [Validate.rules.required()],
        last_name: [Validate.rules.required()],
        business_name: [Validate.rules.required()],
        people_number: [Validate.rules.required("change")],
        category_id: [Validate.rules.required("change")],
        conditions: [Validate.rules.required()]
      },

      categories: [],
      hasCrmUsing: [
        "No he utilizado con anterioridad herramientas de ventas",
        "Utilizo hojas de cálculos para rastrear mis datos de ventas",
        "He utilizado un CRM para mis actividades de ventas"
      ],
      peopleNumbers: PeopleNumbers
    };
  },
  methods: {
    handleRemoveImage() {
      this.registerForm.business_logo = null;
    },

    async nextStep() {
      let formValid = await this.$refs.registerForm.validate();
      if (formValid && this.active <= 3) {
        this.registerResponse = false;

        if (this.active === 0) {
          let response = await this.$store.dispatch("validateEmailExist", {
            email: this.registerForm.email
          });
          if (response.data.exist) {
            this.errorsResponse = response.data.message;
            this.registerResponse = true;
            return false;
          }
        }
        if (this.active === 3) {
          try {
            let response = await this.$store.dispatch(
              "register",
              this.registerForm
            );
            if (response) {
              location.href = "/dashboard?onBoarding=welcome";
              this.successRegisterResponse = true;
              this.active++;
            }
          } catch (error) {
            this.errorsResponse =
              "Imposible crear tu cuenta, por favor intenta nuevamente";
            this.registerResponse = true;
            return false;
          }
          return false;
        }

        this.active++;
      }
    },

    backStep() {
      if (this.active-- === 0) this.active = 0;
    },

    async handleChangeFile(file) {
      let base64 = await fileBase64(file.raw);
      this.registerForm.business_logo = base64;
    },

    formValidate: () => {
      return new Promise((resolve, reject) => {
        this.$refs.registerForm.validate(valid => {
          if (valid) resolve(true);
          reject(false);
        });
      });
    },

    async getCategories() {
      await CategoriesServices.getCategories().then(response => {
        if (response.data.success) this.categories = response.data.categories;
      });
    }
  },
  components: {
    SideBarComponent,
    NavComponent,
    TransitionBounceComponent,
    AlertInfoComponent,
    TermsCondition
  },

  mounted() {
    this.getCategories();
  }
};
</script>

<style scoped lang="scss">
@import "./Register.scss";
</style>
